import React, { useId, useState } from "react";
import classnames from "classnames";

import ChevronIcon from "components/Icons/ChevronIcon";

const FAQ: React.FC<{
  answer: string;
  initialShowPanel?: boolean;
  onClick?: () => void;
  question: string;
}> = ({ answer, initialShowPanel, onClick, question }) => {
  const [showPanel, setShowPanel] = useState(initialShowPanel);

  const handleClick = () => {
    onClick?.();

    setShowPanel(!showPanel);
  };

  const sectionId = useId();
  const buttonId = useId();

  return (
    <div className="border border-light-grey rounded py-lg px-lg bg-white mb-lg">
      <button
        aria-controls={sectionId}
        aria-expanded={showPanel}
        className="items-center flex justify-between w-full py-xs"
        data-testid="faq-question"
        id={buttonId}
        onClick={handleClick}
      >
        <h3 className="flex-grow font-bold text-left pr-xl heading--s">
          {question}
        </h3>
        <div className="text-green flex-none">
          <ChevronIcon direction={showPanel ? "up" : "down"} />
        </div>
      </button>
      <div
        aria-labelledby={buttonId}
        className={classnames("dangerously-set-html pt-xs", {
          hidden: !showPanel,
        })}
        dangerouslySetInnerHTML={{ __html: answer || "" }}
        data-testid="faq-answer"
        id={sectionId}
      />
    </div>
  );
};

export default FAQ;
